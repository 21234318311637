export enum RouterNameEnum {
  LOGIN = 'Login',
  DEFAULT_LAYOUT = 'DefaultLayout',
  HOME = 'Home',
  TRANSACTION_LIST = 'TransactionList',
  TRANSACTION_DETAIL = 'TransactionDetail',
  TRANSACTION = 'Transaction',
  BILL = 'BILL',
  BILL_DETAIL = 'BillDetail',
  ERROR = 'Error',
  NOT_FOUND = 'NotFound',
  SETTLE = 'Settle',
  MERCHANT_SETTLEMENT = 'merchantSettlement',
  CHARGEBACK = 'chargeback',
  QUOTE = 'quote',
  CURRENCY = 'currency',
  MERCHANT = 'merchant',
  MERCHANT_LIST = 'merchantList',
  MERCHANT_APP = 'merchantApp',
  MERCHANT_APP_STORE = 'merchantAppStore',
  MERCHANT_APP_STORE_DOMAIN = 'merchantAppStoreDomain',
  MERCHANT_APP_WEBHOOK = 'merchantAppWebhook',
  PAYMENT = 'Payment',
  Language = 'Language',
  USER_UPDATE = 'UserUpdate',
  ANALYSIS = 'analysis',
  SETTLE_MANAGE = 'SettleManage',
  DEMO = 'Demo',
  TEST = 'Test',
  REPLACE_PAYMENT = 'ReplacePayment',
  ACTIVITY = 'ACTIVITY',
  ACTIVITY_XY = 'ACTIVITY_XY',
  ACTIVITY_ADD_UP = 'ACTIVITY_ADD_UP',
  ACTIVITY_ADD_UP_REPLAY = 'ACTIVITY_ADD_UP_REPLAY',
  ACTIVITY_FIRST_BUY = 'ACTIVITY_FIRST_BUY',
  ACTIVITY_LIMIT = 'ACTIVITY_LIMIT',
  RECHARGE_HISTORY = 'RECHARGE_HISTORY',
  FINANCE = 'Finance',
  WITHDRAW_CASH = 'WITHDRAW_CASH',
  ACTIVITY_RECHARGE = 'ACTIVITY_RECHARGE',
  ACTIVITY_SIGN = 'ACTIVITY_SIGN',
  ACTIVITY_SIGN_REPLAY = 'ACTIVITY_SIGN_REPLAY',
  ACTIVITY_CONSECUTIVE = 'ACTIVITY_CONSECUTIVE',
  ACTIVITY_CONSECUTIVE_REPLAY = 'ACTIVITY_CONSECUTIVE_REPLAY',
  ACTIVITY_LOTTERY = 'ACTIVITY_LOTTERY',
  ACTIVITY_LOTTERY_REPLAY = 'ACTIVITY_LOTTERY_REPLAY',
  ACTIVITY_CONVERT_KEY = 'ACTIVITY_CONVERT_KEY',
  VIP = 'VIP',
  VIP_RANK = 'VIP_RANK',
  VIP_BENEFIT = 'VIP_BENEFIT',
  VIP_POINTS = 'VIP_POINTS',
  VIP_CONFIG = 'VIP_CONFIG',
  VIP_APP = 'VIP_APP',
  ORDER_PURCHASE_HISTORY = 'ORDER_PURCHASE_HISTORY'
}
